import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchCrossing } from '../../../actions';

import Loader from '../../Loader';
import SetHelmet from '../../helmet';

import formatDate from '../../../utils/formatDate';
import getBorderName from '../../../utils/getBorderName';

import PerceptionGraph from '../../d3/graph/animated';
import CrossingImages from '../image';

import CrossingsList from '../list';
import PostButton from '../postButton';

class CrossingView extends React.Component {
  componentDidMount() {
    this.props.fetchCrossing(this.props.match.params.id);
  }

  componentDidUpdate() {
    if (
      this.props.match.params.id !== this.props.crossing?._id &&
      !this.props.loader.bordr
    ) {
      // only fetch if not already fetched
      this.props.fetchCrossing(this.props.match.params.id);
    }
  }

  renderEditButton() {
    if (!this.props.crossing || !this.props.crossing._id) {
      return;
    }

    if (
      this.props.crossing._crosser._id !== this.props.auth.userId &&
      this.props.auth.permission !== 1
    ) {
      return;
    }

    return (
      <Link
        to={`/cross/${this.props.crossing._id}`}
        className="ui button primary"
      >
        Edit
      </Link>
    );
  }

  renderImages() {
    // console.log("images", this.props.crossing.images);
    if (!this.props.crossing.images || this.props.crossing.images.length < 1) {
      return (
        <img
          className="ui fluid rounded image"
          src="../square-image.png"
          alt="Some thing"
        ></img>
      );
    }

    const images = Object.values(this.props.crossing.images);
    const { direction, date, description } = this.props.crossing;
    const { locationA, locationB } = this.props.crossing._border;
    const borderName = getBorderName(direction, locationA, locationB);
    const { REACT_APP_AWS_S3 } = process.env;

    return images.map((image) => {
      // conver object to array
      const { imageFile } = image;
      const imageUri = REACT_APP_AWS_S3 + '/' + imageFile;

      return (
        <img
          key={imageFile}
          className="ui fluid rounded image"
          src={imageUri}
          alt={`${borderName} ${date} ${description}`}
        />
      );
    });
  }

  renderDate() {
    if (!this.props.crossing || !this.props.crossing._id) {
      return;
    }

    const { date } = this.props.crossing;

    const formattedDate = formatDate(date);

    return formattedDate;
  }

  renderCrossing() {
    if (!this.props.crossing || !this.props.crossing._id) {
      return (
        <div className="eight wide column">
          <Loader loader={this.props.loader} />
        </div>
      );
    }

    const { direction, description, perceptions } = this.props.crossing;
    const { locationA, locationB } = this.props.crossing._border;
    const borderName = getBorderName(direction, locationA, locationB);
    const { name, _id } = this.props.crossing._crosser;

    // set preview image for social media or use default image
    const REACT_APP_AWS_S3 = process.env.REACT_APP_AWS_S3;
    const previewImage =
      this.props.crossing.images && this.props.crossing.images[0]
        ? `${REACT_APP_AWS_S3}/${
            Object.values(this.props.crossing.images)[0].imageFile
          }`
        : 'default-social.png';

    return (
      <>
        <SetHelmet
          title={`Crossing the ${borderName} border, ${name}'s Bordr Story`}
          description={description}
          image={previewImage}
          canonical={`https://db.bordr.org/crossing/${this.props.crossing._id}`}
        />
        <div className="twelve wide column">
          {this.renderEditButton()}
          <div className="ui header">
            <div className="sub header">
              {this.renderDate()}{' '}
              <Link
                to={`/crosser/${_id}`}
                style={{ color: '#474747', fontWeight: 'bolder' }}
              >
                {name}
              </Link>{' '}
              crossed the border from
            </div>
            <div className="header">
              <h1>{borderName}</h1>
            </div>
          </div>
          <div className="ui two column stackable grid">
            <div className="column">
              <CrossingImages
                crossing={this.props.crossing}
                borderName={borderName}
              />
            </div>
            <div className="column">
              <p>{description}</p>
              <h3>Perceptions</h3>
              <PerceptionGraph perceptions={perceptions} />
            </div>
          </div>
          <div className="ui divider"></div>
          <p className="ui basic segment center aligned">
            <Link to={`/border/${locationA}-x-${locationB}`}>
              View more stories from the {borderName} border
            </Link>
          </p>
          <div className="ui secondary segment center aligned">
            <PostButton />
          </div>
        </div>
        <div className="fifteen wide column">
          {this.props.crossing.perceptions?.experience > 2 && (
            <>
              <div className="ui basic padded segment center aligned">
                <h2>
                  This crossing from {borderName} was similar in experience to
                  crossing
                </h2>
              </div>
              <div className="ui three column grid stackable">
                <div className="row">
                  <CrossingsList
                    crossings={this.props.crossing.similarExperience}
                    loader={this.props.loader}
                    className="column"
                    cardOnly={true}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  }

  render() {
    return (
      <div className="ui stackable grid">
        <div className="row centered">{this.renderCrossing()}</div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  if (!state.crossings || !state.crossings.crossings) {
    return {
      auth: state.auth,
      loader: state.loader,
    };
  }

  const crossing = state.crossings.crossings[ownProps.match.params.id] || null;

  return {
    auth: state.auth,
    crossing,
    loader: state.loader,
  };
};

export default connect(mapStateToProps, { fetchCrossing })(CrossingView);
