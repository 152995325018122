import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import BorderMap from "../../d3/map";
import { graphCrossings, fetchCrossings, showModal } from "../../../actions";
import SetHelmet from "../../helmet";
import CrosssingsList from "../../crossing/list";

class Welcome extends React.Component{

    componentDidMount() {
        this.props.graphCrossings({perceptions: true});
    }

    handleLoginClick = (e) => {
        // prevent default behavior
        e.preventDefault();
        this.props.showModal('login')
    }

    render() {
        if (!this.props.graph || !this.props.graph[0] || this.props.graph[0].length === 0) {
            return;
        }

        const {graph} = this.props;

        return(
            <>
            <SetHelmet />
            <div className="ui stackable grid">
                <div className="row centered">
                    <div className="six wide column">
                        <div className="ui padded segment">
                        <h1>
                            People cross borders
                        </h1>
                        <p>Borders run across continents, countries, and neighborhoods. 
                            They cut between emotions and divide objects, ideas, and communities. There are 
                            everyday borders and life changing big ones. People come together over border 
                            stories.</p>
                        <p>What border are you crossing?</p>
                        <Link to="/login" className="ui button primary" onClick={this.handleLoginClick}>
                            Join to share your border stories
                        </Link>
                        </div>
                    </div>
                    <div className="six wide column">
                    </div>
                    <div id="welcomeMap">
                        <BorderMap 
                            data={graph}
                        />
                    </div>
                </div>
                <div className="row centered">
                    <div className="eight wide column">
                        <div className="ui stackable grid feed">
                            <div className="row centered">
                                <div className="sixteen wide column">
                                    <h2>Crossing stories</h2>
                                    <CrosssingsList loader={{bordr: false}} crossings={this.props.crossings} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row centered">
                    <Link to="/login" className="ui button primary" onClick={this.handleLoginClick}>
                        What border have you crossed?
                    </Link>
                </div>
            </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    if (!state.graph || state.graph.length === 0 || !state.graph[0]) {
        return {};
    }

    // sort crossings by date
    const sortedCrossings = state.crossings && state.crossings.crossings ? Object.values(state.crossings.crossings).sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
    }) : [];

    const { page = 1, count = 10 } = state.crossings ? state.crossings : {};

    return {
        graph: Object.values(state.graph),
        crossings: sortedCrossings,
        loader: state.loader,
        page: page,
        total: count
    }
}

export default connect(mapStateToProps, {graphCrossings, fetchCrossings, showModal})(Welcome);