import React from 'react';

import { connect } from 'react-redux';

import { showModal } from '../../../actions';

const PostButton = ({isSignedIn, showModal}) => {

    const handleLoginClick = (e) => {
        e.preventDefault();
        showModal('login');
    }

    const handlePostClick = (e) => {
        e.preventDefault();
        showModal('post');
    }

    return (
            <div className="ui padded">
                <h3>Do you have a story about crossing this border?</h3>
                {isSignedIn ? 
                (
                    <button className="ui button primary" onClick={handlePostClick}>
                        Post Crossing
                    </button>
                ) : (
                <button className="ui button primary" onClick={handleLoginClick}>
                    Join and share your border story
                </button>
                )}
            </div>
    );
}

const mapStateToProps = (state) => {
    return {
        isSignedIn: state?.auth?.userId,
    }
}

export default connect(mapStateToProps, { showModal })(PostButton);
