import React from 'react';
import { connect } from 'react-redux';
import * as d3 from 'd3';

class PerceptionGraph extends React.Component {
  componentDidMount() {
    // if data values are all -1, don't draw the graph
    const data = this.props.data;
    const values = Object.values(data);
    const allNegative = values.every((value) => value === -1);
    if (allNegative) {
      return;
    }

    if (this.props.data) {
      this.drawGraph();
    }
  }

  drawGraph() {
    const data = this.props.data;

    // d3 horizontal bar chart
    // https://bl.ocks.org/mbostock/3885304

    // get the width and height of the container
    const margin = { top: 20, right: 35, bottom: 35, left: 60 };
    const width =
      this.refs.crossingPerceptions.clientWidth - margin.left - margin.right;
    const height = 200;

    const x = d3.scaleLinear().range([0, width]);

    const y = d3.scaleBand().range([height, 0]);

    const xAxis = d3.axisBottom(x);

    const yAxis = d3.axisLeft(y);

    const svg = d3
      .select(this.refs.crossingPerceptions)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    x.domain([0, 10]);
    y.domain(
      data.map(function (d) {
        return d.label;
      })
    ).padding(0.1);

    svg
      .append('g')
      .attr('class', 'x axis')
      .attr('transform', 'translate(0,' + height + ')')
      .call(xAxis)
      .append('text')
      .attr('class', 'label')
      .attr('x', width)
      .attr('y', -6);

    svg
      .append('g')
      .attr('class', 'y axis')
      .call(yAxis)
      .append('text')
      .attr('class', 'label')
      .attr('transform', 'rotate(-90)')
      .attr('y', 6)
      .attr('dy', '.71em');

    svg
      .selectAll('.bar')
      .data(data)
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('x', 0)
      .attr('height', y.bandwidth())
      .attr('y', function (d) {
        return y(d.label);
      })
      .attr('width', 0) // start width at 0 for animation
      .transition() // start a transition
      .duration(2000) // duration of transition in milliseconds
      .attr('width', function (d) {
        return x(d.value);
      });

    // set a label for min and max values
    svg
      .append('text')
      .attr('class', 'label')
      .attr('x', 0)
      .attr('y', height + margin.bottom - 5)
      .style('text-anchor', 'start')
      .text('Low');

    svg
      .append('text')
      .attr('class', 'label')
      .attr('x', width)
      .attr('y', height + margin.bottom - 5)
      .style('text-anchor', 'end')
      .text('High');
  }

  render() {
    if (!this.props.data) {
      return <div>no data</div>;
    }

    return <div ref="crossingPerceptions"></div>;
  }
}

const mapStateToProps = (state, ownProps) => {
  const defaultData = {
    visibility: 5,
    experience: 0,
    importance: 5,
    artificiality: 5,
    speed: 0,
    cost: 0,
    danger: 0,
  };

  const data = ownProps.perceptions || defaultData;

  // sort data by key alphabetically reversed
  const sortedData = {};
  Object.keys(data)
    .sort()
    .reverse()
    .forEach((key) => {
      sortedData[key] = data[key];
    });
  // convert defaultData object to array of objects
  const dataPrepped = Object.keys(sortedData)
    .map((key, i) => {
      return {
        label: key,
        value: data[key],
      };
    })
    .filter((item) => {
      return (
        item.value >= 0 &&
        item.label !== '_id' &&
        typeof item.value === 'number'
      );
    });

  return {
    data: dataPrepped,
  };
};

export default connect(mapStateToProps)(PerceptionGraph);
