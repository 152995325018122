import React from 'react';
import { motion, useInView } from 'framer-motion';
import PerceptionGraph from './index';

const AnimatedPerceptionGraph = ({ perceptions }) => {
  const ref = React.useRef();
  const isInView = useInView(ref, { once: true });

  const variants = {
    open: { opacity: 1 },
    closed: { opacity: 0 },
  };

  return (
    <div className="flex flex-col overflow-hidden">
      <motion.div
        ref={ref}
        animate={isInView ? 'open' : 'closed'}
        variants={variants}
        transition={{ duration: 1 }}
      >
        {isInView && <PerceptionGraph perceptions={perceptions} />}
      </motion.div>
    </div>
  );
};

export default AnimatedPerceptionGraph;
