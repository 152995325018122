import React from 'react';

import { useParams } from 'react-router-dom';

import { fetchBorderCrossings } from '../../../actions';
import { connect } from 'react-redux';

import CrossingsList from '../../crossing/list';
import PostButton from '../../crossing/postButton';
import SetHelmet from '../../helmet';

import Loader from '../../Loader';

const BorderView = ({fetchBorderCrossings, crossings, loader}) => {

    const { name } = useParams();
    // split name into two locations by '-x-'
    const [locationA, locationB] = name.split('-x-');
    const borderName = locationA + ' - ' + locationB;

    const [mount, setMount] = React.useState(false);

    React.useEffect(() => {
        if (!mount) {
            fetchBorderCrossings(name);
            setMount(true);
        }
    }, [mount, fetchBorderCrossings, name]);

    if (!crossings) {
        return (
            <div className="ui stackable grid">
                <div className="row centered">
                    <h1>Stories from the {borderName} border</h1>
                </div>
                <div className="row centered">
                    <div className="seven wide column">
                        <Loader
                            loader={{bordr: true}}
                        />
                    </div>
                </div>
            </div>
        );
    }

    const description = `There ${crossings?.length===1 ? `is one story` :  `are ${crossings.length} stories`} on Bordr about crossing the ${borderName} border. What's your experience?`;
    const previewImage = crossings?.length ? crossings[0].images[0] : null;

    return (
        <>
            <SetHelmet 
                title={`Stories from the ${borderName} border`} 
                description={description}
                image={previewImage}
                canonical={`https://db.bordr.org/border/${name}`}
            />
            <div className="ui stackable grid">
                <div className="row centered">
                    <h1>Stories from the {borderName} border</h1>
                </div>
                <div className="row centered">
                    <div className="seven wide column">
                        {crossings?.length && <CrossingsList
                            loader={{...loader, bordr: false}}
                            crossings={crossings}
                        />}
                    </div>
                </div>
                <div className="row centered">
                    <PostButton />
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state, ownProps) => {

    if (!state.crossings.crossings) {
        return {
            loader: state.loader
        }
    }

    // sort crossings by date
    const sortedCrossings = Object.values(state.crossings.crossings).sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
    });

    return {
        auth: state.auth,
        crossings: sortedCrossings,
        loader: state.loader
    };
}

export default connect(mapStateToProps, { fetchBorderCrossings })(BorderView);