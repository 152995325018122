import React from "react";

import { Link } from "react-router-dom";

const ByLine = ({crosser}) => {

    const { name, _id } = crosser;

    return (
        <h4 className="ui user">
            <Link to={`/crosser/${_id}`} style={{color: 'black'}}>
                <i className="user outline icon"></i>{name}
            </Link>    
        </h4>
    )
}

export default ByLine;