import {
    CROSSER_RESULTS
} from '../actions/types';

const INITIAL_STATE = {
    crossers: {
        results: []
    },
    page: 1
};

const searchReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CROSSER_RESULTS:
            return { ...state, crossers: {results: action.payload} };
        default:
            return state;
    }
}

export default searchReducer;