import './App.css';

import React from 'react';

import Notice from './Notice';

import Navigation from './navigation';
import Footer from './footer';

import LoginModal from './auth/loginModal';
import EditModal from './crossing/editModal';

import Login from './auth/login';
import CheckEmailNotice from './auth/email';

import Home from './home';
import CrossingEdit from './crossing/edit';
import CrossingView from './crossing/view';
import BorderView from './border/view';
import ProfileView from './crossers/view';
import EditProfile from './profile/edit';
import Updates from './updates';

import { Router, Route } from 'react-router-dom';
import history from '../history';

import ScrollToTop from '../utils/scrollToTop';
import CrosserFeed from './home/crossers';

class App extends React.Component {
    render() {
        return (
            <div>
                <Router history={history}>
                    <ScrollToTop />
                    <Navigation />
                    <Notice />
                    <div className="article slim"> 
                        <Route path="/" exact component={Home}/>
                        <Route path="/crossers" component={CrosserFeed}/>
                        <Route path="/crossing/:id" component={CrossingView}/>
                        <Route path="/crosser/:id" component={ProfileView}/>
                        <Route path="/border/:name" component={BorderView}/>
                    </div>
                    <div className="article">
                        <Route path="/login" exact component={Login}/>
                        <Route path="/edit/profile" component={EditProfile}/>
                        <Route path="/login/email_sent" exact component={CheckEmailNotice}/>
                        <Route path="/cross/:id" component={CrossingEdit}/>
                        <Route path="/updates" component={Updates}/>
                    </div>
                    <div className="spacer"></div>
                    <Footer />
                    <LoginModal />
                    <EditModal />
                </Router>
            </div>
        );
    }
}

export default App;