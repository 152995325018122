import React, {useEffect, useState } from 'react';
import {useDropzone} from 'react-dropzone';
import Cropper from 'react-easy-crop';
import Compressor from 'compressorjs';
import Loader from '../Loader';

import { Grid, Image as Img } from 'semantic-ui-react';

const img = {
  display: 'block',
  aspectRatio: '1/1',
};

const dropzone = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'lightgrey',
  textAlign: 'center',
  padding: '2em',
  border: '2px dashed grey',
  borderRadius: '5px',
  margin: '0.5em 0 0 0',
  cursor: 'pointer',
  height: '100%',
  aspectRatio: '1/1'
}

const deleteBtnStyle = {
  position: 'absolute',
  top: '2em',
  left: '2em',
  zIndex: '1',
}

const ImageUpload = ({input, label, meta, onChange, maxImages = 1,
  handleDeleteImage, desiredWidth = 1000, desiredHeight = 1000}) => {

  const [files, setFiles] = useState([]);

  // get the files from the input if there are any on load
  useEffect(() => {
    if (input.value) {
      setFiles(input.value);
    }
  }, [input.value]);

  const [croppedArea, setCroppedArea] = React.useState(null);
	const [crop, setCrop] = React.useState({ x: 0, y: 0 });
	const [zoom, setZoom] = React.useState(1);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    accept: {
      'image/*': []
    },
    onDrop: acceptedFiles => {
      // merge the new files with the old files
      const newFiles = [...files, ...acceptedFiles];

      setFiles(newFiles);
      input.onChange(newFiles);
    },
    maxFiles: maxImages
  });

  const deleteFile = (file) => {
    const newFiles = files.filter(f => f.name !== file.name);
    setFiles(newFiles);
    input.onChange(newFiles);
    handleDeleteImage(file.imageFile);
  }

  const removeFile = (file) => {
    const newFiles = files.filter(f => f.name !== file.name);
    setFiles(newFiles);
    input.onChange(newFiles);
  }

  const renderError = ({error, touched}) => {
    if (touched && error) {
        return (
          <div className="ui error">
              <div className="ui pointing red basic label">
                <i className="fas fa-exclamation-triangle"></i>
                {error}
            </div>
          </div>
        );
    }
  }

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
		setCroppedArea(croppedAreaPixels);
	};
  
  const createImage = (url) =>
    new Promise(async (resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => {
        image.onload = () => {
          // console.log("image onload", image);
        }
        resolve(image);
      });
      image.addEventListener("error", reject);
      image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues
      image.src = url;
	});


  const getCroppedImg = async (imageSrc, pixelCrop, rotation = 0, imageName) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate(rotation * Math.PI / 180);
    ctx.translate(-safeArea / 2, -safeArea / 2);

    // draw rotated image and store data.
    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5
    );

    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // console.log("canvas.width", canvas.width);
  
    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
      data,
      0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
      0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
    );
  
    // 
    // let file = canvas.toBlob('image/jpeg', 0.9);
    let file = await new Promise((resolve, reject) => {
      canvas.toBlob(async file => {
        file = new Compressor(file, {
          width: desiredWidth,
          height: desiredHeight,
          quality: .75,
          
          success(result) {
            
            resolve(
              Object.assign(result, {
                name: "cropped_"+imageName,
                preview: URL.createObjectURL(result),
                croppedImage: true
              })
            );
          },
          error(err) {
            
            reject(err);
          }
        });
      }, 'image/jpeg', 0.75);
    })
    // add seconds delay to allow for image to load
    // await new Promise(resolve => setTimeout(resolve, 2000));

    return file;

  }

  const thumbs = files.map(file => {
    
    if (file.croppedImage) {
      return (
      <Grid.Column key={file.name} className='cropped_thumbnails relative'>
            <button 
              className="ui icon button negative" style={deleteBtnStyle} 
              onClick={() => removeFile(file)}>
                <i className="trash icon"></i>
            </button>
            <Img
              src={file.preview}
              style={img}
              alt="new files"
              className='rounded-2xl'
              // Revoke data uri after image is loaded
              // onLoad={() => { URL.revokeObjectURL(file.preview) }}
            />
      </Grid.Column>
      )
    } else if (file._id) {
      // show existing images on the build stored in AWS
      const { REACT_APP_AWS_S3 } = process.env;
      const imageUri = REACT_APP_AWS_S3 + '/' + file.imageFile;

      return (
        <Grid.Column key={file._id} className='cropped_thumbnails relative'>
          <button
            className="ui icon button negative" style={deleteBtnStyle}
            onClick={() => deleteFile(file)}>
            <i className="trash icon"></i>
          </button>
          <Img 
              src={imageUri} 
              alt="existing file" 
              className='rounded-2xl'
          />
        </Grid.Column>
      );
    } else if (file.cropper) {
      return (
        <div key={file.name} style={{zIndex: '1000', position: 'relative'}}>
          <Cropper
            image={file.cropper}
            crop={crop}
            zoom={zoom}
            aspect={1}
            
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}

            className="cropperContainer"
          />
          <div className="reactEasyCrop_Controls">
            <button className="ui button primary large" 
                onClick={async (event) => {
                  // on button click disable button and change text to loading
                  event.target.disabled = true;
                  event.target.innerText = "saving...";

                  event.preventDefault();

                  const croppedImage = await getCroppedImg(
                    file.cropper,
                    croppedArea,
                    0,
                    file.name
                  );

                  // wait a few seconds to allow for image to load
                  // await new Promise(resolve => setTimeout(resolve, 1000));
                  const newFiles = [croppedImage, ...files.filter(f => f.name !== file.name)];
                  // await new Promise(resolve => setTimeout(resolve, 1000));

                  setFiles(newFiles);
                  input.onChange(newFiles);

                }}
                >
                  crop
            </button>
          </div>
        </div>
      )
      }
      return (
        <Loader key={file.name} active type='inline' />
      )
  });

  useEffect (() => {
    if (files.length > 0) {
      
      //  resize images
      files.forEach(async file => {

        if (!file.cropper && !file.croppedImage && !file._id) {
          const reader = new FileReader();
          reader.addEventListener('load', async () => {
            window.scrollTo(0,0);

            // load new image into cropper
            const image = new Image();
            image.src = reader.result;
            image.onload = async () => {
              const width = image.width;
              const height = image.height;

              // console.log('image loaded, width: ', width, ' height: ', height);
              
              // if the loaded image is too large, resize it before loading into cropper
              if (image.width > 1500 || image.height > 1500) {
                // console.log('image too large, resizing...');

                // resize image proportionally to max width or height of 4000
                const maxWidth = 1500;
                const maxHeight = 1500;
                let newWidth = width;
                let newHeight = height;
                if (width > height) {
                  if (width > maxWidth) {
                    newHeight *= maxWidth / width;
                    newWidth = maxWidth;
                  }
                } else {
                  if (height > maxHeight) {
                    newWidth *= maxHeight / height;
                    newHeight = maxHeight;
                  }
                }
                
                // resize image proportionally
                // make up file name with letters and numbers
                const imageName = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

                const resizedImage = await new Promise((resolve, reject) => {
                  new Compressor(file, {
                    width: newWidth,
                    height: newHeight,
                    quality: 0.9,
    
                    success(result) {
                      const image = {
                        ...result,
                        cropper: URL.createObjectURL(result),
                        croppedImage: false,
                        name: imageName
                      };
                      resolve(image);
                    },
                    error(err) {
                      
                      reject(err);
                    }
                  });
                });
                const newFiles = [resizedImage, ...files.filter(f => f.name !== file.name)];
                setFiles(newFiles);
                input.onChange(newFiles);

              } else {
                // console.log('image small enough, loading into cropper...');

                // resize image proportionally
                // make up file name with letters and numbers
                const imageName = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

                const prepImage = {
                  ...file,
                  cropper: URL.createObjectURL(file),
                  croppedImage: false,
                  name: imageName
                };
                
                const newFiles = [prepImage, ...files.filter(f => f.name !== file.name)];
                
                setFiles(newFiles);
                input.onChange(newFiles);
              }
            }

          });
          reader.readAsDataURL(file);
        }
      });
    }
  }, [files, input]);

  // revoke data uris once the component is unmounted. Earlier and we get occasional broken images
  useEffect(() => {
    return () => {
      files.forEach(file => URL.revokeObjectURL(file.preview));
    }
  }, [files]);

  return (
    <div style={{position: 'relative'}}>
      {renderError(meta)}
      <b>{label}</b>
      <Grid stackable columns={2}>
          {maxImages >= (files.length+1) && (
            <Grid.Column>
              <div {...getRootProps({className: 'dropzone'})} style={dropzone}>
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop the file here</p>
                ) : (
                <p>Tap to select an image or take photo</p>
                )}
              </div>
            </Grid.Column>
            )}
          {thumbs}
    </Grid>
    </div>
  );
}

export default ImageUpload;