import { combineReducers } from "redux";
import authReducer from "./authReducer";
import crossingsReducer from "./crossingsReducer";
import bordersReducer from "./bordersReducer";
import noticeReducer from "./noticeReducer";
import loaderReducer from "./loaderReducer";
import modalReducer from "./modalReducer";
import searchReducer from "./searchReducer";
import graphReducer from "./graphReducer";
import crossersReducer from "./crossersReducer";

export default combineReducers({
    auth: authReducer,
    loader: loaderReducer,
    modal: modalReducer,
    notice: noticeReducer,
    search: searchReducer,
    crossings: crossingsReducer,
    crossers: crossersReducer,
    borders: bordersReducer,
    graph: graphReducer
})