import React from "react";

import { connect } from "react-redux";
import { Form, Field } from "react-final-form";

import CustomInput from "../../forms/CustomInput";

import { updateCrosser, fetchCrosser } from "../../../actions";

class EditProfile extends React.Component {

    componentDidMount() {
        this.props.fetchCrosser();
    }

    render() {
        return (
            <div>
                <h1>Edit Profile</h1>
                {this.renderForm()}
            </div>
        );
    }

    renderForm() {
        if (!this.props || !this.props.auth) {
            return;
        }
        return (
            <Form 
                initialValues={this.props.auth}
                onSubmit={this.onSubmit}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <form onSubmit={handleSubmit} className="ui form error">
                        <Field name="name" component={CustomInput} label="Name" />
                        <Field name="email" component={CustomInput} label="Email" />
                        <button className="ui button primary" type="submit" disabled={submitting || pristine}>
                            Submit
                        </button>
                    </form>
                )}
            />
        )
    }

    onSubmit = (values) => {
        this.props.updateCrosser(values);
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        loader: state.loader
    }
}

export default connect(mapStateToProps, {updateCrosser, fetchCrosser})(EditProfile);