import React from "react";
import ReactDOM from "react-dom";


const Loader = ({className, loader, onDismiss}) => {

    if (loader?.bordr) {

        // return following JSX 3 times
        const loader = (
            <div className={className}>
            <div className="ui placeholder fluid">
                <div className="header">
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="spacer"></div>
                </div>
                <div className="header image">
                        <div className="square image"></div>
                </div>
                <div className="content">
                    <div className="line"></div>
                    <div className="line"></div>
                </div>
            </div>
            </div>
        );
        
        return (
            <div>
                {loader}
            </div>
        );
    } else {
        return ReactDOM.createPortal(
            <div onClick={onDismiss} className="ui dimmer modals visible active" style={{position: "fixed", overflow: "auto"}}>
                <div className="ui active inverted dimmer">
                    <div className="ui small text loader"></div>
                </div>
            </div>,
            document.querySelector('#loader')
        );
    }
};

export default Loader;