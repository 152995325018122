import React from "react";
// input is the destructured version of formProps.input

function renderError({error, touched}) {
    if (touched && error) {
        return (
            <div className="ui error">
                <div className="ui pointing red basic label">{error}</div>
            </div>
        );
    }
}

const renderTextarea = ({input, label, meta}) => {
    const className = `field ${meta.error && meta.touched ? 'error': ''}`
    return (
        <div className={className}>
            <label>{label}</label>
            <textarea
                // onChange={formProps.input.onChange}
                // value={formProps.value}
                // is the same as
                {...input} 
                style={{height: "1.5em"}}
                autoComplete="off"
            />
            {renderError(meta)}
        </div>
        );
}

export default renderTextarea;