const formatDate = (date) => {
    // formate date to recent format (e.g. 2 days ago)
    const dateObj = new Date(date);
    const now = new Date();
    const diff = now.getTime() - dateObj.getTime();
    // const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
    const diffDays = Math.floor(diff / (1000 * 3600 * 24));
    
    if (diffDays < 1) {
        const diffHours = Math.floor(diff / (1000 * 3600));
        if (diffHours < 1) {
            const diffMinutes = Math.floor(diff / (1000 * 60));
            if (diffMinutes < 1) {
                return "Just now";
            }
            if (diffMinutes === 1) {
                return "A minute ago";
            }
            return `${diffMinutes} minutes ago`;
        }
        if (diffHours === 1) {
            return "An hour ago";
        }
        return `${diffHours} hours ago`;
    }

    console.log(diff, (1000 * 3600 * 24));

    if (diffDays === 1) {
        return "Yesterday";
    } else if (diffDays < 7) {
        return `${diffDays} days ago`;
    } else if (diffDays < 31) {
        const diffWeeks = Math.ceil(diffDays / 7);
        return `${diffWeeks} weeks ago`;
    } else if (diffDays < 365) {
        const diffMonths = Math.ceil(diffDays / 30);
        if (diffMonths === 1) {
            return "A month ago";
        }
        return `${diffMonths} months ago`;
    } else {
        const diffYears = Math.ceil(diffDays / 365);
        if (diffYears === 1) {
            return "A year ago";
        }
        return `${diffYears} years ago`;
    }
};

export default formatDate;