import React from "react";
import Modal from "../../Modal";
import Login from "../login";

import { connect } from "react-redux";
import { hideModal } from "../../../actions";

class LoginModal extends React.Component {

    onClose = (e) => {
        e.preventDefault();
        this.props.hideModal();
    }

    render() {
        if (!this.props.modal || !this.props.modal.visible || this.props.modal.modalType !== 'login') {
            return null;
        }
        return (
            <Modal
                title="Login"
                content={<Login />}
                onDismiss={this.onClose}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        modal: state.modal
    }
}

export default connect(mapStateToProps, { hideModal })(LoginModal);