import React from "react";

const CheckEmailNotice = () => {
    return (
        <div className="ui two column centered grid">
            <div className="column">
                <div className="ui icon message">
                    <i className="envelope outline icon"></i>
                    <div className="content">
                        <div className="header">
                            Please check your email for a link to login.
                        </div>
                        <p>If you do not see it, please check your spam folder.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CheckEmailNotice;