import React from "react";
import { Link } from "react-router-dom";

import Loader from "../../Loader";

import CrossingsList from "../../crossing/list";

class CrossersList extends React.Component{

    
    render() {
        const { crossers } = this.props;

        if (!crossers || crossers.length === 0) {
            return <Loader />;
        }

        const renderCrossers = () => {
            return crossers.map((crosser, index) => {
                const crossingData = crosser.crossings.map((crossing) => {
                    return {...crossing, _crosser: {name: crosser.name, _id: crosser._id} }
                })
                return (
                    <div className="ui stackable grid segment" key={index}>
                        <div className="row">
                            <div className="sixteen wide column center aligned">
                                <h3 className="ui user"><Link to={`/crosser/${crosser._id}`} style={{color: 'black'}}><i className="user outline icon"></i>{crosser.name}</Link></h3>
                                <div className="ui grid">
                                    <CrossingsList 
                                        loader={{bordr: false}} 
                                        crossings={crossingData} 
                                        thumbnail={true}    
                                        className="eight wide column"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>  
                )
            })
        }

        return(
            <>
                {renderCrossers()}
            </>
        )
    }
}

export default CrossersList;