import React from "react";
import { Link } from "react-router-dom";

import SetHelmet from "../../helmet";

import { connect } from "react-redux";
import { fetchCrossers } from "../../../actions";

import CrossersList from "../../crossers/list";

class CrosserFeed extends React.Component{

    componentDidMount() {
        this.props.fetchCrossers();
    }

    renderHelmet() {

        return (
            <SetHelmet 
            title={`Bordr - Border Crossers`} 
        />
        )
    }

    render() {

        return(
            <>
            {this.renderHelmet()}
            <div className="ui stackable grid feed">
                <div className="row centered">
                    <div className="seven wide column">
                        <div className="ui menu">
                            <div className="item">
                                <Link to='/'>Latest Crossings</Link>
                            </div>
                            <div className="item active">
                                <b>Crossing Collections</b>
                            </div>
                        </div>
                        <CrossersList 
                            loader={this.props.loader} 
                            crossers={this.props.crossers} 
                        />
                    </div>
                </div>
            </div>
            </>
        )
    }
}

function mapStateToProps(state) {

    if (!state.crossers) {
        return {
            loader: state.loader
        }
    }

    const crossers = state.crossers ? Object.values(state.crossers) : [];

    return {
        crossers,
        loader: state.loader
    }
}

export default connect(mapStateToProps, { fetchCrossers })(CrosserFeed);