import React from "react";
import { Helmet } from "react-helmet";

const SetHelmet = (props) => {

    const {
        title = "Bordr.org - What border are you crossing?",
        description = "An app for crossing bordrs",
        canonical = "https://db.bordr.org/",
        image = "default-social.png"
    } = props;

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <link rel="canonical" href={canonical} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
            <meta property="og:url" content={canonical} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@crossbordr" />
            <meta name="twitter:creator" content="@crossbordr" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />
        </Helmet>
    );
}

export default SetHelmet;