import React from "react";

import Welcome from "./welcome";
import Feed from "./feed";
import Loader from "../Loader";

import { connect } from "react-redux";

class Home extends React.Component{

    render() {

        if (this.props.auth && this.props.auth.userId) {
            return <Feed />
        } else if (this.props.auth && this.props.auth.permission) {
            return <Welcome />
        } else {
            return <Loader />
        }

    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        loader: state.loader
    }
}

export default connect(mapStateToProps, null
)(Home); 