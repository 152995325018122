import React from "react";
import { Field, Form } from "react-final-form";
import renderInput from "../../forms/CustomInput";

import { connect } from "react-redux";
import { loginEmail } from "../../../actions";

const Login = (props) => {

    const onSubmit = (formValues) => {
        props.loginEmail(formValues);
    }
       
    const renderMagicLinkForm = () => {
        return (
            <Form
                onSubmit={onSubmit}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} className="ui form error">
                        <div className="one column stackable ui grid">
                            <div className="column">
                                <Field
                                    name="email"
                                    component={renderInput}
                                    placeholder="Enter your email address"
                                    inputStyle={{width:"80%"}}
                                />
                                <button 
                                    className="ui button primary" 
                                    type="submit"
                                >Sign-in with Email</button>
                            </div>
                        </div>
                    </form>
                )}
            />   
        )
    }

    return (
        <>
        <div className="ui stackable grid center aligned">
            <div className="ui two column row">
            <div className="ui column middle aligned grid">
                <div className="ui basic segment">
                <div className="ui header center aligned">Welcome!</div>
                <p>To get started or to sign-in use the "Sign-in with Google" link or enter your email address and tap "Sign-in with Email".</p>
                <p><strong>New here?</strong> If you do not have an account, you will be prompted to complete a brief profile upon login.</p>
                </div>
            </div>
            <div className="ui column center aligned grid">
                <div className='middle aligned row'>
                    <div className='column'>
                        <a className="ui button" href="/auth/google" style={{width:"80%"}}>
                        <i className='icon google'></i>
                        Sign-in with Google
                        </a>
                    </div> 
                    </div>
                <div className="ui horizontal divider">Or</div>
                    <div className='middle aligned row'>
                    <div className='column'>
                        <div className="ui form">
                            {renderMagicLinkForm()}
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
        </>
    );

}

export default connect(null, { loginEmail })(Login);