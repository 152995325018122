export const SET_LOADER = 'set_loader';

export const AUTH_CROSSER = 'auth_crosser';
export const LOG_OUT = 'log_out';
export const FETCH_CROSSER = 'fetch_crosser';

export const FETCH_CROSSERS = 'fetch_crossers';

export const ADD_NOTICE = 'add_notice';
export const HIDE_NOTICE = 'hide_Notice';
export const DELETE_NOTICE = 'delete_notice';

export const ADD_MODAL = 'add_modal';
export const HIDE_MODAL = 'hide_modal';
export const DELETE_MODAL = 'delete_modal';

export const FETCH_CROSSINGS = 'fetch_crossings';
export const POST_CROSSING = 'post_crossing';
export const FETCH_CROSSING = 'fetch_crossing';
export const DELETE_CROSSING = 'delete_crossing';
export const DELETE_CROSSING_IMAGE = 'delete_crossing_image';
export const REACT_CROSSING = 'react_crossing';

export const FETCH_BORDERS = 'fetch_borders';
export const FETCH_BORDER = 'fetch_border';

export const FETCH_CROSSER_BY_ID = 'fetch_crosser_by_id';

export const CROSSER_RESULTS = 'crosser_results';

export const GRAPH_CROSSINGS = 'graph_crossings';