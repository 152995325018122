import React from "react";
import { Link } from "react-router-dom";

import Loader from "../../Loader";
import SetHelmet from "../../helmet";

import { connect } from "react-redux";
import { fetchCrossings } from "../../../actions";

import getBorderName from "../../../utils/getBorderName";

import CrosssingsList from "../../crossing/list";

import Pagination from "../../pagination";
import CrossingImages from "../../crossing/image";

class Feed extends React.Component{

    componentDidMount() {
        this.props.fetchCrossings();
    }
    
    renderCrossings() {
        
        if (!this.props.crossings || !this.props.crossings.length || this.props.loader.bordr) {
            return <Loader loader={this.props.loader} />
        }

        return this.props.crossings.map(crossing => {
            if (!crossing._border) {
                return null;
            }

            const { _id, direction, description } = crossing;
            const { locationA, locationB } = crossing._border;
            const { name } = crossing._crosser;
            const borderName = getBorderName(direction, locationA, locationB);

            return(
                <div className="ui card fluid" key={_id}>
                    <div className="content">
                        <div className="right floated"><i className="user outline icon"></i>{name}</div>
                        <Link to={`/crossing/${_id}`} className="header">
                        {borderName}
                        </Link>
                    </div>
                    <Link to={`/crossing/${_id}`}>
                        <CrossingImages crossing={crossing} borderName={borderName} />
                    </Link>
                    <div className="content">
                        <div className="description">

                            {description}
                        </div>
                    </div>
                </div>
            )
        })
    }

    renderHelmet() {

        return (
            <SetHelmet 
            title={`Bordr - Latest Stories`} 
        />
        )
    }

    render() {

        return(
            <>
            {this.renderHelmet()}
            <div className="ui stackable grid feed">
                <div className="row centered">
                    <div className="eight wide column">
                        <div className="ui menu">
                            <div className="item active">
                                <b>Latest Crossings</b>
                            </div>
                            <div className="item">
                                <Link to='/crossers/'>Crossing Collections</Link>
                            </div>
                        </div>
                        <CrosssingsList 
                            loader={this.props.loader} 
                            crossings={this.props.crossings} 
                        />
                        <Pagination 
                            page={this.props.page}
                            total={this.props.total}
                            handleClick={this.props.fetchCrossings}
                        />
                    </div>
                </div>
            </div>
            </>
        )
    }
}

function mapStateToProps(state) {

    if (!state.crossings.crossings) {
        return {
            loader: state.loader
        }
    }

    // sort crossings by date
    const sortedCrossings = Object.values(state.crossings.crossings).sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
    });

    const { page = 1, count = 10 } = state.crossings;

    return {
        crossings: sortedCrossings,
        loader: state.loader,
        page: page,
        total: count
    }
}

export default connect(mapStateToProps, { fetchCrossings })(Feed);