import React from "react";
import { TransitionablePortal, Segment, Header } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { addNotice, hideNotice, deleteNotice } from "../actions";

class Notice extends React.Component {

    componentDidMount() {
        this.expireNotice();
    }

    expireNotice() {
        if (!this.props.notice) {
            return;
        }
        setTimeout(() => {
            if (!this.props.notice) {
                return;
            }
            const { time } = this.props.notice;
            const timeElapsed = new Date() - time;
      
            if (timeElapsed>4500) {
                if (this.props.visible) {
                    this.props.hideNotice();
                    setTimeout(() => {
                        this.props.deleteNotice();
                    }, 1000);
                }
            }
        }, 5000);
    }

    render() {
        if (!this.props.notice) {
            return;
        } 

        this.expireNotice();

        const { notice, type } = this.props.notice;

        return (
        <TransitionablePortal 
            open={this.props.visible}
            transition={{animation: "fade down", duration: 500 }}
        >
        <Segment
            className={`message ${type}`}
            style={{ 
                left: '25%', 
                width: '50%', 
                position: 'fixed', 
                top: '2em', 
                zIndex: 1000 
            }}
        >
            <Header>{notice}</Header>
        </Segment>
        </TransitionablePortal>
    );
    }

};


const mapStateToProps = (state) => {
    return { 
        notice: state.notice.notice,
        visible: state.notice.visible
    };
};

export default connect(
    mapStateToProps, 
    {addNotice,hideNotice,deleteNotice} // refers to the two action creators
)(Notice);