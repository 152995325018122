import React from "react";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { fetchCrosser, showModal } from "../../actions";

class Navigation extends React.Component{

    componentDidMount() {
        this.props.fetchCrosser();  
    };

    renderName() {
        if (!this.props.auth || !this.props.auth.name) {
            return;
        }
        return this.props.auth.name;
    }

    checkIfMobile() {
        if (window.innerWidth < 768) {
            console.log("mobile");
            return true;
        }
        return false;
    }

    renderPostButton() {
        if (!this.props.auth || !this.props.auth.userId) {
            return;
        } else {
            return(
                <div className="item">
                    <Link to='/cross/new' onClick={this.handlePostClick}>
                        <div className="ui button primary">
                            Post Crossing
                        </div>
                    </Link>
                </div>
            )
        }
    }

    handleLoginClick = (e) => {
        // prevent default behavior
        e.preventDefault();
        this.props.showModal('login')
    }

    handlePostClick = (e) => {
        if (this.checkIfMobile()) {
            return;
        }
        // prevent default behavior
        e.preventDefault();
        this.props.showModal('post')
    }

    renderUserDropdown() {
        if (!this.props.auth || !this.props.auth.userId) {
            return(
                <div className='ui simple dropdown item'>
                    <Link to='/login' onClick={this.handleLoginClick} >
                        <i className='icon user outline'></i>
                        Login
                    </Link>
                </div> 
            )
        } else {
            return(
                <div className='ui simple dropdown item'>
                    <i className='icon user outline'></i>
                        <div className='username'>
                            {this.renderName()}
                        </div>
                    <div className='menu'>
                        <Link className="item" to='/edit/profile'>
                            Profile
                        </Link>
                        <a className="item" href='/api/logout'>
                        Logout
                        </a>
                    </div>
                </div> 
            )
        }
    }

    render(){
        return(
            <div className='ui top attached menu'>
                <div className="item">
                    <Link to='/' style={{color:'black', margin:'0',fontSize: '0.8em'}}>
                        <h1>bordr</h1>
                    </Link>
                </div>
                <div className='right menu'>
                    {this.renderPostButton()}
                    {this.renderUserDropdown()}
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth
    }
}

// export default connect(mapStateToProps, {fetchUser})(Navigation);
export default connect(mapStateToProps, {
    fetchCrosser, showModal
})(Navigation);