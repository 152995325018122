import { 
    GRAPH_CROSSINGS
} from '../actions/types';

const graphReducer = (state = {}, action) => {
    switch (action.type) {
        case GRAPH_CROSSINGS:
            return { ...state, ...action.payload }
        default:
            return state;
    }
};

export default graphReducer;