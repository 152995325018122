import './index.css';
import React from "react";

const CrossingImages = ({ crossing, borderName }) => {

    const carouselRef = React.useRef(null);
    const [currentSlide, setCurrentSlide] = React.useState(0);

    const onScroll = () => {
        const scrollPosition = carouselRef.current.scrollLeft;
        const slideWidth = carouselRef.current.children[0].offsetWidth;
        const currentSlide = Math.round(scrollPosition / slideWidth);
        setCurrentSlide(currentSlide);
    };

    if (!crossing.images || crossing.images.length < 1) {
        return <img className="ui fluid image" src="../square-image.png" alt="Some thing"></img>;
    }

    const { date, description, images } = crossing;
    const { REACT_APP_AWS_S3 } = process.env;

    const renderImages = Object.values(images).map((image,index) => {
        // conver object to array
        const { imageFile } = image;
        const imageUri = REACT_APP_AWS_S3 + '/' + imageFile;

        if (!imageFile) {
            return null;
        }

        return (
        <div className='slide' key={imageFile}>
            <img 
                src={imageUri} 
                alt={`${borderName} ${date} ${description}`} 
                className="ui fluid image"
                />
        </div>
        );

    });

    const renderDots = Object.values(images).map((image,index) => {
        // conver object to array
        const { imageFile } = image;
        return (
            <div 
                className={`dot ${currentSlide === index && 'active'}`} 
                key={imageFile}
            ></div>
        );

    });

    return (
        <>
        <div className="carousel" ref={carouselRef} onScroll={onScroll}>
            {renderImages}
        </div>
        {images.length>1 && (
            <div className="dots">
                {renderDots}
            </div>
        )}
        </>
    );
}

export default CrossingImages;