import { ADD_NOTICE, HIDE_NOTICE, DELETE_NOTICE } from '../actions/types';

const INITIAL_STATE = {
    notice: null,
    visible: false
}

const noticeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADD_NOTICE:
            return { ...state, notice: action.payload, visible: true };
        case HIDE_NOTICE:
            return { ...state, visible: false };
        case DELETE_NOTICE:
            return { ...state, notice: null, visible: false };
        default:
            return state;
    }
};

export default noticeReducer;