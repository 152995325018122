import _ from "lodash";
import { 
    FETCH_CROSSERS,
    FETCH_CROSSER_BY_ID
} from '../actions/types';

const crossersReducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_CROSSERS:
            return { ...state, ..._.mapKeys(action.payload, '_id') };
        case FETCH_CROSSER_BY_ID:
            return { ...state, [action.payload._id]: action.payload };
        default:
            return state;
    }
};

export default crossersReducer;