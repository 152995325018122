import React from "react";

import { connect } from "react-redux";

import { useParams } from "react-router-dom";

import { fetchCrosserById } from "../../../actions";

import Loader from "../../Loader";

import CrossingsList from "../../crossing/list";
import SetHelmet from "../../helmet";

const Profile = ({crosser, crossings, fetchCrosserById}) => {

    const [mounted, setMounted] = React.useState(false);
    const { id } = useParams();

    React.useEffect(() => {
        if (!mounted) {
            fetchCrosserById(id);
            setMounted(true);
        }
    }, [mounted, fetchCrosserById, id])

    if (!crosser || !crossings) {
        return <Loader />
    }

    const { name } = crosser;

    const crossingData = crosser.crossings.map((crossing) => {
        return {...crossing, _crosser: {name: crosser.name, _id: crosser._id} }
    })

    // gett first border story with an image from the first crossing
    const firstCrossing = crosser.crossings[0];

    const description = `Bordr.org is a collection of border crossing stories. ${name}'s border crossing stories.`

    return (
        <>
        <SetHelmet 
            title={`${name}'s Collection of Bordr Stories`} 
            description={description}
            image={firstCrossing.images[0].imageFile}
            canonical={`https://db.bordr.org/crossing/${firstCrossing._id}`}
        />
        <div className="ui container center aligned">
            <h1>{name}</h1>
            <h2>Border Crossings</h2>
            <div className="ui stackable grid feed">
                <div className="row centered">
                    <div className="nine wide column">
                        <CrossingsList 
                            profileView 
                            crossings={crossingData} 
                            loader={{bordr: false}} 
                    />
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

const mapStateToProps = (state, ownProps) => {
    return {
        crosser: state.crossers[ownProps.match.params.id],
        crossings: state.crossings
    }
}

export default connect(mapStateToProps, { fetchCrosserById })(Profile);