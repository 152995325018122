import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Form, Field } from 'react-final-form';

import CustomInput from '../../forms/CustomInput';
import CustomTextarea from '../../forms/CustomTextarea';
import RenderCustomSelect from '../../forms/CustomSelectCreate';
import CustomSlider from '../../forms/CustomSlider';
import ImageUpload from '../../forms/ImageUpload';

import DeleteModal from '../deleteModal';

import {
  fetchCrossing,
  postCrossing,
  hideModal,
  showModal,
  deleteCrossing,
  deleteCrossingImage,
  searchCrossers,
} from '../../../actions';

const PerceptionLabels = {
  visibility: {
    label: 'How visible was the border crossing?',
    name: 'visibility',
    initialValue: -1,
    minLabel: {
      style: {
        color: 'red',
      },
      label: 'N/A',
    },
    midLabel: 'Invisible',
    maxLabel: 'Visible',
  },
  importance: {
    label: 'How important is this border crossing to you?',
    name: 'importance',
    initialValue: -1,
    minLabel: {
      style: {
        color: 'red',
      },
      label: 'N/A',
    },
    midLabel: 'Unimportant',
    maxLabel: 'Important',
  },
  quality: {
    label: 'How was the quality of the border crossing?',
    name: 'artificiality',
    initialValue: -1,
    minLabel: {
      style: {
        color: 'red',
      },
      label: 'N/A',
    },
    midLabel: 'Natural',
    maxLabel: 'Artificial',
  },
  speed: {
    label: 'How long did it take to cross this border?',
    name: 'speed',
    initialValue: -1,
    minLabel: {
      style: {
        color: 'red',
      },
      label: 'N/A',
    },
    midLabel: 'No time',
    maxLabel: 'A lot of time',
  },
  cost: {
    label: 'How was the cost of the border crossing?',
    name: 'cost',
    initialValue: -1,
    minLabel: {
      style: {
        color: 'red',
      },
      label: 'N/A',
    },
    midLabel: 'Cheap',
    maxLabel: 'Expensive',
  },
  danger: {
    label: 'How was the danger of the border crossing?',
    name: 'danger',
    initialValue: -1,
    minLabel: {
      style: {
        color: 'red',
      },
      label: 'N/A',
    },
    midLabel: 'Safe',
    maxLabel: 'Dangerous',
  },
  experience: {
    label: 'How was your experience crossing the border?',
    name: 'experience',
    initialValue: -1,
    minLabel: {
      style: {
        color: 'red',
      },
      label: 'N/A',
    },
    midLabel: 'Negative',
    maxLabel: 'Positive',
  },
};

class CrossingEdit extends React.Component {
  submit = (event) => {
    event.preventDefault();
    this.props.onSubmit();
  };

  onClose = (e) => {
    e.preventDefault();
    this.props.hideModal();
  };

  componentDidMount() {
    if (
      !this.props.auth ||
      !this.props.crossing ||
      !this.props.match ||
      !this.props.match.params ||
      this.props.match.params.id === 'new'
    ) {
      return;
    }
    this.props.fetchCrossing(this.props.match.params.id);
  }

  confirmDelete = () => {
    // open modal to confirm delete
    // if confirmed, delete crossing
    // redirect to home page
    this.props.showModal('delete');
  };

  renderDeleteButton() {
    if (
      this.props.modal &&
      this.props.modal.visible &&
      this.props.modal.modalType === 'post'
    ) {
      return;
    }

    // if post is new
    if (
      !this.props.match ||
      !this.props.match.params ||
      this.props.match.params.id === 'new'
    ) {
      return;
    }

    return (
      <button onClick={this.confirmDelete} className="ui button negative basic">
        Delete
      </button>
    );
  }

  handleDeleteImage = async (imageFile) => {
    const id = this.props.match.params.id;
    if (!id) {
      return;
    }
    await this.props.deleteCrossingImage(id, imageFile);
  };

  renderActions() {
    // if (this.props.modal && this.props.modal.visible && this.props.modal.modalType === 'post') {
    //     return;
    // }

    // disable submit button if submitting
    // const { submitting, pristine } = this.props;

    const showOnClick = this.props.modal.visible ? this.onClose : null;
    const submitting = this.props.loader.form;

    return (
      <>
        <div className="row" style={{ margin: '1.5em 0 0 0' }}>
          <div className="column">
            <button
              form="crossingForm"
              type="submit"
              onClick={(event) => {
                // disable button
                this.submit(event);
              }}
              className="ui button primary"
              disabled={submitting}
            >
              Post Crossing
            </button>
            <Link to={`/`} onClick={showOnClick} className="ui button basic">
              Cancel
            </Link>
            {this.renderDeleteButton()}
          </div>
        </div>
      </>
    );
  }

  onSubmit = async (formValues) => {
    if (
      !this.props.match ||
      !this.props.match.params ||
      !this.props.match.params.id
    ) {
      return await this.props.postCrossing('new', formValues);
    }
    return await this.props.postCrossing(
      this.props.match.params.id,
      formValues
    );
  };

  renderCrosserDropdown() {
    let crossers = this.props.crossers;

    // if initial value has a crosser, add it to the dropdown
    if (this.props.crossing && this.props.crossing._crosser) {
      crossers = [
        {
          value: this.props.crossing._crosser._id,
          label: this.props.crossing._crosser.name,
        },
        ...crossers,
      ];
    }

    // remove duplicates
    crossers = crossers.filter(
      (crosser, index, self) =>
        index === self.findIndex((t) => t.value === crosser.value)
    );

    if (this.props.auth && this.props.auth.permission === 1) {
      return (
        <div className="row">
          <div className="column">
            <div className="field">
              <label>Who are you?</label>
              <Field
                name="crosser"
                component={RenderCustomSelect}
                options={crossers}
                defaultOptions={crossers}
                searchAction={this.props.searchCrossers}
              ></Field>
            </div>
          </div>
        </div>
      );
    }
  }

  renderPerceptionSliders() {
    return Object.keys(PerceptionLabels).map((perception, index) => {
      return (
        <div className="row" key={index}>
          <div className="column">
            <div className="field">
              <Field
                name={`perceptions.${PerceptionLabels[perception].name}`}
                label={PerceptionLabels[perception].label}
                component={CustomSlider}
                min={-1}
                mid={0}
                max={10}
                step={0.1}
                minLabel={PerceptionLabels[perception].minLabel}
                midLabel={PerceptionLabels[perception].midLabel}
                maxLabel={PerceptionLabels[perception].maxLabel}
                defaultValue={PerceptionLabels[perception].initialValue}
              />
            </div>
          </div>
        </div>
      );
    });
  }

  renderForm() {
    const initialValues =
      this.props.crossing && this.props.crossing._border
        ? {
            ...this.props.crossing,
            crossingFrom: this.props.crossing.direction
              ? this.props.crossing._border.locationA
              : this.props.crossing._border.locationB,
            crossingTo: this.props.crossing.direction
              ? this.props.crossing._border.locationB
              : this.props.crossing._border.locationA,
          }
        : {
            crossingFrom: '',
            crossingTo: '',
            description: '',
            crosser: this.props.auth.userId,
          };

    return (
      <Form
        initialValues={initialValues}
        onSubmit={this.onSubmit}
        validate={(formValues) => {
          const errors = {};

          if (!formValues.crossingFrom) {
            errors.crossingFrom = 'Please enter where you came from';
          }

          if (!formValues.crossingTo) {
            errors.crossingTo = 'Please enter where you are';
          }

          if (!formValues.description) {
            errors.description = 'You must enter a description';
          }

          return errors;
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => {
          this.submit = handleSubmit;
          return (
            <form
              id="crossingForm"
              onSubmit={handleSubmit}
              className="ui form error"
            >
              {this.renderCrosserDropdown()}
              <div className="ui grid equal width">
                <div className="row">
                  <div className="column">
                    <Field
                      name="crossingFrom"
                      component={CustomInput}
                      label="From"
                      placeholder="There"
                    />
                  </div>
                  <div className="column">
                    <Field
                      name="crossingTo"
                      component={CustomInput}
                      label="To"
                      placeholder="Here"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="two column">
                  <Field
                    name="images"
                    component={ImageUpload}
                    label="Image of your crossing"
                    maxImages={2}
                    handleDeleteImage={this.handleDeleteImage}
                  />
                </div>
                <div className="ui clear"></div>
              </div>
              <div className="row" style={{ marginTop: '2em' }}>
                <div className="column">
                  <Field
                    name="description"
                    component={CustomTextarea}
                    label="Share something about your experience crossing this border?"
                    placeholder="I crossed the border from the United States to Mexico. I was traveling with my family and we were driving. We were stopped at the border and asked to show our passports. We were allowed to cross."
                  />
                </div>
              </div>
              <div
                className="row"
                style={{ marginTop: '2em', paddingBottom: '1em' }}
              >
                <div className="column">{this.renderPerceptionSliders()}</div>
              </div>
            </form>
          );
        }}
      />
    );
  }

  render() {
    // unless crossing is new, check if crossing is loaded
    if (
      this.props.crossing &&
      !this.props.crossing._border &&
      !this.props.newCrossing
    ) {
      return <div>Loading...</div>;
    }

    // does user have permission to edit this crossing?
    // if not, redirect to home page
    if (
      !this.props.newCrossing &&
      this.props.crossing._crosser._id !== this.props.auth.userId &&
      this.props.auth.permission !== 1
    ) {
      this.props.history.push('/');
    }

    return (
      <div className="ui column">
        {this.renderForm()}
        {this.renderActions()}
        <DeleteModal crossing={this.props.crossing} />
      </div>
    );
  }
}

const getCrosserOptions = (crossers, auth) => {
  if (
    !crossers ||
    !crossers.length ||
    crossers.length === 0 ||
    !crossers[0].name
  ) {
    return [
      {
        value: auth.userId,
        label: auth.name,
      },
    ];
  }
  return crossers.map((crosser) => {
    return {
      value: crosser._id,
      label: crosser.name,
    };
  });
};

const mapStateToProps = (state, ownProps) => {
  const crossOptions = getCrosserOptions(
    state.search.crossers.results,
    state.auth
  );

  if (
    !ownProps.match ||
    !ownProps.match.params ||
    !ownProps.match.params.id ||
    ownProps.match.params.id === 'new' ||
    !state.crossings.crossings
  ) {
    return {
      auth: state.auth,
      crossing: {},
      modal: state.modal,
      crossers: crossOptions,
      term: state.term,
      loader: state.loader,
      newCrossing: true,
    };
  } else if (
    state.crossings.crossings &&
    state.crossings.crossings[ownProps.match.params.id]
  ) {
    return {
      auth: state.auth,
      crossing: {
        ...state.crossings.crossings[ownProps.match.params.id],
      },
      modal: state.modal,
      crossers: crossOptions,
      term: state.term,
      loader: state.loader,
      newCrossing: false,
    };
  }
};

export default connect(mapStateToProps, {
  fetchCrossing,
  postCrossing,
  hideModal,
  showModal,
  searchCrossers,
  deleteCrossingImage,
  deleteCrossing,
})(CrossingEdit);
