import React from "react";
import ReactDOM from "react-dom";

const Modal = props => {
    return ReactDOM.createPortal(
        <div onClick={props.onDismiss} className="ui dimmer modals visible active" style={{position: "fixed", overflow: "auto"}}>
            <div onClick={(e)=>e.stopPropagation()} className="ui standard modal visible active">
                <div className="ui header">{props.title}</div>
                <div className="ui basic segment scrolling content">
                    {props.content}
                </div>
                <div className="actions">
                    {props.actions}
                </div>
            </div>
        </div>,
        document.querySelector('#modal')
    );
};

export default Modal;