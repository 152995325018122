import React from 'react';

const Updates = () => {
  return (
    <div className="ui container">
      <div className="ui stackable grid">
        <div className="row">
          <div className="sixteen wide column">
            <h1>What's New</h1>
            <p>
              Updates to Bordr.org. If you are interested in helping out on the
              project please reach out to Christo de Klerk on{' '}
              <a href="https://twitter.com/christodeklerk">Twitter</a> or on{' '}
              <a href="https://github.com/jcdk">Github</a>.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="sixteen wide column">
            <h2>March, 2024</h2>
            <p>
              Quality replaced with "artificiality" on the perception
              measurement, because nature doesn't mean low quality, right? Added
              a small animation to the graph on load and fixed a couple of other
              small bugs.
            </p>
            <h2>July, 2023</h2>
            <p>
              Borders now have dedicated pages with a listing of crossing
              stories in either direction. Other updates include styling of the
              crossing pages and more contextual invitations to share your
              border story.
            </p>
            <h2>June, 2023</h2>
            <p>
              Starting to see some clicks and impressions from search. To make
              it more inviting to join and to increase search discoverability,
              I've added a sample of crossings on the home page that correspond
              with the visualization.
            </p>
            <p>
              Added profile pages for border crossers which displays their
              crossings as a collection. Added tabs on the main feed, one for
              Latest Crossings and the other for a listing of all Crossing
              Collections.
            </p>
            <p>
              Added comparable border stories by experience rating on the border
              crossing detail page. Because very negative experiences often are
              linked with tragic circumstances, I've suppressed showing
              comparisons from very negative border stories.
            </p>
            <h2>May, 2023</h2>
            <p>
              Multi-image border stories can now be submitted. Great for sharing
              what one side of the border versus the other looks like. Using
              Prerender.io to make it possible to share a border story snippet
              on social media.
            </p>
            <h2>April, 2023</h2>
            <p>
              Bordr returns online rebuilt completely in React-Redux, Mongodb,
              and deployed on Railway. CSS framework is Semantic UI.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Updates;
