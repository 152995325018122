import _ from "lodash";
import { 
    FETCH_CROSSINGS,
    FETCH_CROSSING, 
    DELETE_CROSSING,
    REACT_CROSSING,
    POST_CROSSING,
    DELETE_CROSSING_IMAGE
} from '../actions/types';

const crossingsReducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_CROSSINGS:
            return { 
                ...state, 
                crossings: {..._.mapKeys(action.payload.crossings, '_id')},
                page: action.payload.page,
                count: action.payload.count
            };
        case FETCH_CROSSING:
            return { 
                ...state, 
                crossings: {[action.payload._id]: action.payload}
            }
        case DELETE_CROSSING_IMAGE:
            return { crossings: {[action.payload.id]: {
                ...state.crossings[action.payload.id],
                // remove the image from the images array
                images: _.remove
                // iterate over the images array
                (state.crossings[action.payload.id].images, (image) => image.imageFile !== action.payload.imageFile)
            }}};
        case DELETE_CROSSING:
            return _.omit(state.crossings, action.payload);
        case REACT_CROSSING:
            return { ...state, crossings: {[action.payload._id]: action.payload} }
        case POST_CROSSING:
            return { ...state, crossings: {[action.payload._id]: action.payload} }
        default:
            return state;
    }
};

export default crossingsReducer;