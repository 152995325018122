import React from "react";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const RenderSlider = ({input, label, meta, defaultValue, min, mid, max, minLabel, midLabel, maxLabel, step}) => {

    const handleSelectionChange = (value) => {
        if (value === -1 || (value >= 0 && value <= 10)) {
        //   setTargetNumber(value); 
          // return value for use in the parent component
            input.onChange(value);
        }
      }

    const renderHandle = ({props}) => {
        const { ...restProps } = props;
        const value = Math.ceil(restProps['aria-valuenow']*10);
        const valueLabel = value < 0 ? 'Off' : value;

        return (
            <div {...restProps} data-tooltip={valueLabel}>
            </div>
        );
    }
        

    const className = `field ${meta.error && meta.touched ? 'error': ''}`;

    return (
        <div className={className} style={{margin: "0 1em 3.3em", lineHeight: "2em"}}>
            <label>{label}</label>
            <Slider
                // onChange={formProps.input.onChange}
                // value={formProps.value}
                // is the same as
                {...input} 
                defaultValue={defaultValue}
                min={min}
                mid={mid}
                max={max}
                startPoint={0}
                step={step}
                marks={{[min]: minLabel, [mid]: midLabel, [max]: maxLabel}}
                onChange={handleSelectionChange}
                handleRender={renderHandle}
            />
        </div>
        );
}

export default RenderSlider;