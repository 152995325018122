import axios from 'axios';
import history from '../history';
import {
    AUTH_CROSSER,
    FETCH_CROSSER,
    FETCH_CROSSERS,
    FETCH_CROSSER_BY_ID,
    LOG_OUT,
    SET_LOADER,
    ADD_NOTICE,
    HIDE_NOTICE,
    DELETE_NOTICE,
    ADD_MODAL,
    DELETE_MODAL,
    HIDE_MODAL,
    FETCH_CROSSINGS,
    FETCH_CROSSING,
    POST_CROSSING,
    DELETE_CROSSING,
    DELETE_CROSSING_IMAGE,
    REACT_CROSSING,
    FETCH_BORDERS,
    FETCH_BORDER,
    CROSSER_RESULTS,
    GRAPH_CROSSINGS
} from './types';

export const setLoader = (type,bool) => async dispatch => {

    dispatch({
        type: SET_LOADER,
        payload: {[type]: bool}
    });
}

export const addNotice = (notice) => {
    return { 
        type: ADD_NOTICE,
        payload: notice
    }
}

export const hideNotice = () => {
    return {
        type: HIDE_NOTICE
    }
}

export const deleteNotice = () => {
    return {
        type: DELETE_NOTICE
    }
}

export const authCrosser = () => async dispatch => {
    const response = await axios.post('/api/auth');

    dispatch({ type: AUTH_CROSSER, payload: response.data });
    history.push('/crossings');
}

export const loginEmail = (formValues) => async dispatch => {

    const res = await axios.post('/auth/email', formValues);
    
    if (res && res.data && res.data.message) {
        dispatch(hideModal());
        history.push('/login/email_sent');
    } else {
        dispatch(addNotice({notice: "Error logging in", type: "negative", time: new Date()}));
    }

};

export const fetchCrossers = () => async dispatch => {
    const response = await axios.get('/api/crossers/all');
    dispatch({ type: FETCH_CROSSERS, payload: response.data });
    dispatch(setLoader('bordr',false));
}

export const fetchCrosser = () => async dispatch => {
    const response = await axios.get('/api/current_user');
    if (!response.data || !response.data.email) {
        dispatch({
            type: AUTH_CROSSER,
            payload: { permission: 'guest'}
        });
        return;
    }
    dispatch({ type: FETCH_CROSSER, payload: response.data });
}

export const fetchCrosserById = (id) => async dispatch => {
    const response = await axios.get(`/api/crosser/${id}`);
    dispatch({ type: FETCH_CROSSER_BY_ID, payload: response.data });
}

export const updateCrosser = (formValues) => async dispatch => {
    const response = await axios.post('/api/update_user', formValues);
    dispatch({ type: FETCH_CROSSER, payload: response.data });
    dispatch(addNotice({notice: "Profile updated", type: "positive", time: new Date()}));
}

export const logOut = () => async dispatch => {
    await axios.get('/api/logout');
    dispatch({ type: LOG_OUT });
    history.push('/');
}

export const fetchCrossings = (page = 1, list) => async dispatch => {
    dispatch(setLoader('bordr',true));
    const response = await axios.get('/api/crossings', {
        params: {
            page,
            list
        }
    });
    dispatch({ type: FETCH_CROSSINGS, payload: response.data });
    dispatch(setLoader('bordr',false));
}

export const fetchCrossing = id => async dispatch => {
    dispatch(setLoader('bordr',true));
    const response = await axios.get(`/api/crossings/${id}`);
    dispatch({ type: FETCH_CROSSING, payload: response.data });
    dispatch(setLoader('bordr',false));
}

export const postCrossing = (id,formValues) => async dispatch => {

    dispatch(setLoader('form',true));
    dispatch(setLoader('bordr',true));
    
    const { images } = formValues;

    // handle image upload
    if (images && images.length > 0 && images[0].size > 0) {
        const preResponse = await axios.post('/api/crossing', {id, formValues});

        // get id of new crossing
        id = preResponse.data._id;
        
        const imageTypes = images.map(image => image.type);

        const uploadConfig = await axios.get('/api/upload', {
            params: {
                imageTypes,
                acceptType: ['image/jpeg', 'image/png'],
                type: 'crossing',
                id
            }
        });

        await uploadConfig.data.map(async (image, index) => {
            await axios.put(image.url, images[index], {
                headers: {
                    'Content-Type': images[index].type
                }
            });
        });

        const crossingImages = uploadConfig.data.map(image => {
            return { imageFile: image.key };
        });

        formValues = {
            ...formValues, 
            crosser: preResponse.data._crosser,
            images: crossingImages,
            id
        };

    } 

    const response = await axios.post('/api/crossing', {id, formValues});
    id = response.data._id;
    dispatch({ type: POST_CROSSING, payload: response.data });

    // dispatch after five seconds
    setTimeout(() => {        
        dispatch(setLoader('form',false));
        dispatch(setLoader('bordr',false));
        history.push(`/crossing/${id}`);

        dispatch(hideModal());
        dispatch(addNotice({notice: "Crossing made!", type: "positive", time: new Date()}));
    }, 1000);
}

export const deleteCrossing = id => async dispatch => {
    await axios.delete(`/api/crossing/${id}`);
    dispatch({ type: DELETE_CROSSING, payload: id });
    history.push('/');
}

export const deleteCrossingImage = (id, imageFile) => async dispatch => {
    try {
        await axios.delete(`/api/crossing/${id}/images/`, {data: {imageFile}});
    } catch (err) {
        return dispatch(addNotice({notice: "Error deleting image", type: "negative", time: new Date()}));
    } 
    dispatch({ type: DELETE_CROSSING_IMAGE, payload: {id, imageFile} });
    dispatch(addNotice({notice: "Image deleted", type: "positive", time: new Date()}));
}

export const reactCrossing = (id, formValues) => async dispatch => {
    const response = await axios.patch(`/api/crossings/${id}`, formValues);
    dispatch({ type: REACT_CROSSING, payload: response.data });
    history.push('/');
}

export const fetchBorders = () => async dispatch => {
    dispatch(setLoader('bordr',true));
    const response = await axios.get('/api/borders');
    dispatch({ type: FETCH_BORDERS, payload: response.data });
    dispatch(setLoader('bordr',false));
}

export const fetchBorder = id => async dispatch => {
    dispatch(setLoader('bordr',true));
    const response = await axios.get(`/api/borders/${id}`);
    dispatch({ type: FETCH_BORDER, payload: response.data });
    dispatch(setLoader('bordr',false));
}

export const showModal = (modal) => {
    return { type: ADD_MODAL, payload: modal }
}

export const hideModal = () => {
    return { type: HIDE_MODAL }
}

export const deleteModal = () => {
    return { type: DELETE_MODAL }
}

export const searchCrossers = (search) => async dispatch => {
    const response = await axios.get(`/api/crossers/`,
        { params: { search } });
        dispatch( { type: CROSSER_RESULTS, payload: response.data });
}

export const graphCrossings = (perceptions) => async dispatch => {
    const response = await axios.get(`/api/graph/crossings`, { params: perceptions });
    dispatch({ type: GRAPH_CROSSINGS, payload: response.data });

    // dispatch fetchCrossings to update state and pass list of ids to fetchCrossing
    const list = response.data.map(crossing => crossing.id);
    dispatch(fetchCrossings(1,list));
}

export const fetchBorderCrossings = (name, page = 1) => async dispatch => {
    dispatch(setLoader('bordr',true));
    const response = await axios.get(`/api/border/${name}`, {
        params: {
            page
        }
    });
    dispatch({ type: FETCH_CROSSINGS, payload: response.data });
    dispatch(setLoader('bordr',false));
}