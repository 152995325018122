import React from "react";
import { Link } from "react-router-dom";

import Loader from "../../Loader";

import getBorderName from "../../../utils/getBorderName";
import CrossingImages from "../../crossing/image";
import ByLine from "../../crossers/byline";

class CrossingsList extends React.Component{

    renderCrossings() { 

        const className = this.props.className && this.props.className.length ? this.props.className : "ui feed";

        if (!this.props.crossings || !this.props.crossings.length || this.props.loader.bordr) {
            return (
                <>
            <div className={`${className}`}>
                <Loader loader="bordr"/>
            </div>
            <div className={`${className}`}>
                <Loader loader="bordr" />
            </div>
            <div className={`${className}`}>
                <Loader loader="bordr"/>
            </div>
            </>
            );
        }

        return this.props.crossings.map(crossing => {
            if (!crossing._border) {
                return null;
            }

            const { _id, direction, description } = crossing;
            const { locationA, locationB } = crossing._border;
            const borderName = getBorderName(direction, locationA, locationB);

            if (!_id) {
                return null;
            }

            return(
                <div className={`${className}`} key={_id}>
                    <div className="ui card fluid">
                    <div className="content">
                        <Link to={`/crossing/${_id}`} className="header">
                        {borderName}
                        </Link>
                    </div>
                    <Link to={`/crossing/${_id}`}>
                        <CrossingImages crossing={crossing} borderName={borderName} />
                    </Link>
                    {!this.props.thumbnail && (<div className="content">
                        {!this.props.profileView && (<ByLine crosser={crossing._crosser} />)}
                        <div className="description">
                            {!this.props.cardOnly && description}
                        </div>
                    </div>)}
                </div>
            </div>
            )
        })
    }

    render() {
        return(
            <>
                {this.renderCrossings()}
            </>
        )
    }
}

export default CrossingsList;