import React from "react";
import { Pagination as PageHandle } from "semantic-ui-react";

const Pagination = ({ page = 1, total, handleClick }) => {

    // make sure page is a number
    page = Number(page);

    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(total / 10); i++) {
        pageNumbers.push(i);
    }

    const totalPages = pageNumbers.length;

    // onclick call handleClick with page number and go to top of page
    const handlePageClick = (e) => {
        // if e.target.text is « ⟨ ⟩ » then return page number
        switch (e.target.text) {
            case '«':
                e.target.text = 1;
            break;
            case '»':
                e.target.text = totalPages;
            break;
            case '⟨':
                e.target.text = page - 1;
            break;
            case '⟩':
                e.target.text = page + 1;
            break;
            default:
                e.target.text = Number(e.target.text);
            break;
        }
        handleClick(e.target.text);
        window.scrollTo(0, 0);
    };

    // if on first page don't show previous button
    const showFirstItem = page === 1 ? null : undefined;
    const showLastItem = page === totalPages ? null : undefined;
    const showPrevItem = page === 1 ? null : undefined;
    const showNextItem = page === totalPages ? null : undefined;

    // semantic ui pagination
    return (
        <PageHandle 
            totalPages={totalPages} 
            activePage={page} 
            onPageChange={(e)=>handlePageClick(e)}
            firstItem={showFirstItem}
            lastItem={showLastItem}
            prevItem={showPrevItem}
            nextItem={showNextItem}
            boundaryRange={1}
            siblingRange={0}
        />
    );
};


export default Pagination;