import React, { useEffect, useState } from "react";
import { Field } from "react-final-form";
import CreatableSelect from "react-select/creatable";
// import AsyncCreatableSelect from "react-select/async-creatable";

const ReactSelectAdapter = ({ input, onInputChange, defaultOptions, options, isLoading, ...rest }) => (
    <CreatableSelect
        {...input}
        {...rest}
        searchable 
        isClearable={false}
        defaultOptions={options}
        isLoading={isLoading}
        // loadOptions={(value) => promiseOptions(value, options)}
        options={options}
        onInputChange={value => onInputChange(value)}
    />
);

// const promiseOptions = (value, options) =>{
//     console.log("value: ", value);
//     console.log("options: ", options);

//     return new Promise(resolve => {
//         setTimeout(() => {
//             resolve(filterOptions(value, options));
//         }, 500);
//     })};

// const filterOptions = (value, options) => {
//     // filter options based on value
//     return options.filter(o =>
//         o.label.toLowerCase().includes(value.toLowerCase())
//     );
//     // return options;
// }

function renderError({error, touched}) {
    if (touched && error) {
        return (
            <div className="ui error">
                <div className="ui pointing red basic label">{error}</div>
            </div>
        );
    }
}

// only set state if the value is different from the current state
const handleTermChange = (value, term, setTerm) => {
    if (value !== term) {
        setTerm(value);
    }
}
const RenderCustomSelect = ({ input, label, meta, options, searchAction }) => {
    
    const [term, setTerm] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        setLoading(true);
        // search term related to input item
        const search = () => searchAction(term);

        // debounce search
        if (term && term.length > 2 && !options.length) {
            search();
            setInputValue(term);
            setLoading(false);
        } else {
            const timeoutId = setTimeout(() => {
                // if term is not in options, search
                if (term && inputValue!==term) {
                    search();
                    setInputValue(term);
                }
                setLoading(false);
            }, 1000);
            // react will run the return when component is re-reendered.
            return () => {
                clearTimeout(timeoutId);
            }    
        }
    }, [term, input, options, searchAction, inputValue]);

    const className = `field ${meta.error && meta.touched ? 'error': ''}`
    return (
        <div className={className}>
            <label>{label}</label>
            <Field
                {...input} 
                style={{height: "1.5em"}}
                autoComplete="off"
                component={ReactSelectAdapter}
                options={options}
                isLoading={loading}
                defaultValue={options[0]}
                onInputChange={(input) => handleTermChange(input, term, setTerm)}
            />
            {renderError(meta)}
        </div>
        );
}

export default RenderCustomSelect;