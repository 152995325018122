import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <div className="ui vertical footer segment">
            <div className="ui center aligned container">
                <div className="ui stackable grid">
                    <div className="thirteen wide column left aligned">
                        <h4><a href="https://bordr.org">About Bordr.org</a></h4>
                        <p><Link to='/updates/'>What's New</Link></p>
                    </div>
                    <div className="three wide column right aligned">
                        <h4 className="ui header">Contact</h4>
                        <p>hello@bordr.org</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
                        
                        