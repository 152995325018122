import { 
    AUTH_CROSSER,
    FETCH_CROSSER, 
    LOG_OUT 
} from '../actions/types';

const INITIAL_STATE = {
    userId: null,
    email: null,
    name: null,
    permission: null
}

const authReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AUTH_CROSSER:
            return { 
                ...state, 
                userId: action.payload._id,
                name: action.payload.name,
                email: action.payload.email,
                permission: action.payload.permission
            };
        case LOG_OUT:
            return {
                ...state,
                userId: null,
                email: null,
                name: null,
                permissions: null
            }
        case FETCH_CROSSER:
            return {
                ...state,
                userId: action.payload._id,
                name: action.payload.name,
                email: action.payload.email,
                permission: action.payload.permission
            }
        default:
            return state;
    }
};

export default authReducer;