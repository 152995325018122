import React from "react";

import Modal from "../../Modal";
import getBorderName from "../../../utils/getBorderName";

import { connect } from "react-redux";
import { deleteCrossing, hideModal } from "../../../actions";

class DeleteModal extends React.Component {

    renderDeleteContent() {
        if (!this.props.modal || !this.props.modal.visible || this.props.modal.modalType !== 'delete') {
            return null;
        }
        return (
            <div className="ui column" style={{padding: "0 0.5em"}}>
                <h3>Are you sure you want to delete this crossing?</h3>
                <p>This action cannot be undone.</p>
            </div>
        );
    }

    renderDeleteActions() {
        if (!this.props.modal || !this.props.modal.visible || this.props.modal.modalType !== 'delete') {
            return null;
        }

        return (
            <div>
                <button onClick={this.onClose} className="ui button">Cancel</button>
                <button onClick={this.onDelete} className="ui button negative">Delete</button>
            </div>
        );
    }

    onDelete = (e) => {
        e.preventDefault();
        this.props.deleteCrossing(this.props.crossing._id);
        this.props.hideModal();
    }

    onClose = (e) => {
        e.preventDefault();
        this.props.hideModal();
    }

    render() {
        if (!this.props.modal || !this.props.modal.visible || this.props.modal.modalType !== 'delete') {
            return null;
        }

        const { direction } = this.props.crossing;
        const { locationA, locationB } = this.props.crossing._border;
        const borderName = getBorderName(direction, locationA, locationB);
        
        return (
            <Modal
                title={`Delete ${borderName}`}
                content={this.renderDeleteContent()}
                actions={this.renderDeleteActions()}
                onDismiss={this.onClose}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        modal: state.modal
    }
}

export default connect(mapStateToProps, { hideModal, deleteCrossing })(DeleteModal);