import _ from "lodash";
import { 
    FETCH_BORDERS,
    FETCH_BORDER 
} from '../actions/types';

const bordersReducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_BORDERS:
            return { ...state, ..._.mapKeys(action.payload, '_id') };
        case FETCH_BORDER:
            return { ...state, [action.payload._id]: action.payload }
        default:
            return state;
    }
};

export default bordersReducer;