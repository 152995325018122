import React from "react";
import { connect } from "react-redux";
import * as d3 from "d3";

class SimpleBorderMap extends React.Component {

    componentDidMount() {
      if (this.props.data) {
        this.drawMap();
      }
    }
  
    drawMap() {
        const {nodes, links} = this.props.data;
        // const width = 300;
        // const height = 300;

        // get the width and height of the container
        const width = this.refs.borderMap.clientWidth;
        const minHeight = 350;
        const deviceHeight = window.innerHeight/1.5;
        const height = deviceHeight > minHeight ? deviceHeight : minHeight;


        const svg = d3.select(this.refs.borderMap)
            .append('svg')
            .attr('width', width)
            .attr('height', height);

        const simulation = d3.forceSimulation()
            // set the distance between nodes
            // .force('link', d3.forceLink().id(d => d.id))
            .force('link', d3.forceLink().id(d => d.id).strength(0.07))
            .force('charge', d3.forceManyBody().strength(-50))
            .force('center', d3.forceCenter(width / 2, height / 2).strength(.95));

        var link = svg.selectAll(".link")
            .data(links)
            .enter().append("line")
            .attr("class", "link")
            .attr("stroke-opacity", function(d) { return d.visi/10+0.1; })
            .style("stroke-width", function(d) { return d.posi/2+1/2; })
            .style("stroke", "#ffd200");

        var node = svg.selectAll('.node')
            .data(nodes)
            .enter()
            .append("g");
        
        var circle = node.append("circle")
            .attr("class", "circle")
            .attr("r", 52 )
            .attr("fill-opacity", 0)
            .style("stroke", 'none')
            .style("stroke-width", 'none');
        
        var label = node.append("svg:text")
            .attr("class", "nodetext")
            .attr("dx", 0)
            .attr("dy", ".35em")
            .text(function (d) { return d.id; })
            .style("text-anchor", "middle")
            .style("fill", "#555")
            .style("font-family", "Arial")
            .style("font-size", "1em");


        node.call(d3.drag());
      
        // Define the tick function
        function ticked() {
            link.attr("x1", function(d) { return (((d.source.x + d.target.x) / 2) - ((d.target.y - d.source.y) / 2)*.75); })
                            .attr("y1", function(d) { return (((d.source.y + d.target.y) / 2) + ((d.target.x - d.source.x) / 2)*.75); })
                            .attr("x2", function(d) { return (((d.source.x + d.target.x) / 2) + ((d.target.y - d.source.y) / 2)*.75); })
                            .attr("y2", function(d) { return (((d.source.y + d.target.y) / 2) - ((d.target.x - d.source.x) / 2)*.75); });
        
            node.attr('cx', d => d.x)
                .attr('cy', d => d.y);

            circle.attr("cx", function (d) { return d.x; })
                .attr("cy", function (d) { return d.y; });

            label.attr("x", function (d) { return d.x; })
                .attr("y", function (d) { return d.y; });
        }
    
        // Start the simulation
        simulation.nodes(nodes)
        .on('tick', ticked);
    
        simulation.force('link')
        .links(links);
      

    }
      
    render() {
      return <div ref="borderMap"></div>;
    }
  }

  const mapStateToProps = (state, ownProps) => {

    const defaultData = [
        { source: 'A', target: 'B', id: 5, posi: 2, visi: 5 },
        { source: 'B', target: 'D', id: 5, posi: 1, visi: 1 },
        { source: 'C', target: 'B', id: 5, posi: 9, visi: 5 },
    ];

    const data = ownProps.data || defaultData;

    // transform data into nodes and links
    const nodes = [];
    const links = [];
    data.forEach((d) => {
        nodes.push({id: d.source});
        nodes.push({id: d.target});
        links.push({source: d.source, target: d.target, posi: d.posi, visi: d.visi});
    });

    // remove duplicate nodes
    const uniqueNodes = [];
    nodes.forEach((d) => {
        if (!uniqueNodes.find((node) => node.id === d.id)) {
            uniqueNodes.push(d);
        }
    });

    //   test data
    //   const nodes = [
    //     { id: 'A' },
    //     { id: 'B' },
    //     { id: 'C' },
    //   ];    
    //   const links = [
    //     { source: 'A', target: 'B' },
    //     // { source: 'B', target: 'C' },
    //     { source: 'C', target: 'A' },
    //   ];
      
    return {
      data: {nodes: uniqueNodes, links}
  }
}

export default connect(mapStateToProps)(SimpleBorderMap);